// CONTAINER

@mixin container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 2rem;

  @media screen and (min-width: 1800px) {
    max-width: 1400px;
  }
}

// LAYOUT
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

// STYLES

@mixin common-nav-link-styles {
  text-decoration: none;
  color: black;
}
