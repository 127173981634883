@import "../../styles/main.scss";

.work__container {
  padding-bottom: 3rem;

  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }

  .work__tiles__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10rem 5rem;

    @media screen and (max-width: $breakpoint-md) {
      grid-template-columns: 1fr;
      padding: 5rem 1rem;
    }
    @media screen and (max-width: $breakpoint-sm) {
      grid-template-columns: 1fr;
      padding: 0;
    }

    .work__item {
      display: flex;
      justify-content: center;
      width: 100%;

      img {
        max-height: 600px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 40px;
      }
    }
  }
}
