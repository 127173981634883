#menuToggle {
  overflow: hidden;
  position: absolute;
  width: 4rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menuToggle input {
  display: flex;
  width: 60px;
  height: 60px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 99999; /* top of the menu */
}

#menuToggle span{
  width: 50px;
  height: 8px;
  margin-bottom: 10px;
  background: #000;
  border-radius: 4px;
  -webkit-transition: all .5s cubic-bezier(.08,.81,.87,.71);
  -moz-transition: all .5s cubic-bezier(.08,.81,.87,.71);
  -ms-transition: all .5s cubic-bezier(.08,.81,.87,.71);
  -o-transition: all .5s cubic-bezier(.08,.81,.87,.71);
  transition: all .5s cubic-bezier(.08,.81,.87,.71);
}

@media screen and (max-width: 768px) {
  #menuToggle span {
    width: 40px;
    height: 6px;
    margin-bottom: 5px;
  }
  
}

#span1 {
  transform-origin: 7px -1px;
}

#span3 {
  transform-origin: bottom right;
}

#menuToggle input:checked ~ #span1 {
  background-color: #333;
  transform: rotate(45deg) translate(8px);
  z-index: 99999;
}
#menuToggle input:checked ~ #span2 {
  background-color: #333;
  transform: rotate(495deg) translate(4px); 
  z-index: 99999;  
}
#menuToggle input:checked ~ #span3 {
  background-color: #333;
  transform: rotate(45deg);
  opacity: 1;
 /* top of the menu */
}