.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 2rem;

  @media screen and (min-width: 1800px) {
    max-width: 1400px;
  }

  @media screen and (max-width: $breakpoint-md) {
    padding: 0 1rem;
  }
}

.workpage__container, .about__container {
  padding: 1rem 10rem;

  @media screen and (max-width: $breakpoint-lg) {
    padding: 1rem 2rem;
  }
}

.work__page__p {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.5;
  width: 75%;

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
  }
}

.work__page__p:last-of-type {
  margin-top: 0;
  margin-bottom: 2rem;
}

.workpage__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 3rem;

  img {
    max-width: 900px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;

    @media screen and (max-width: $breakpoint-sm) {
      border-radius: 9px;
    }
  }
}

.noradius img {
  border-radius: 0 !important;
}
