@import "../../styles/main.scss";
@import "./Bar.css";
.nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  z-index: 9999;
  box-sizing: border-box;
  font-family: $font-family-title, sans-serif;

  @media screen and (max-width: 768px) {
    height: 8rem;
  }

  .nav__logo {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    font-family: $font-family-h1;

    .nav__link {
      @include common-nav-link-styles;
      font-weight: 800;
    }

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 1.5rem;
    }

    @media screen and (max-width: $breakpoint-sm) {
      margin-left: 1rem;
    }
  }

  .nav__links {
    display: flex;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

    &.show {
      opacity: 1;
      visibility: visible;
    }

    @media screen and (min-width: $breakpoint-lg) {
      .nav__item {
        font-size: 1.8rem !important;
      }
    }

    @media screen and (min-width: 768px) {
      opacity: 1;
      visibility: visible;
      display: flex;
      align-items: center;

      .nav__item {
        margin: 2em;
        font-weight: 600;
        font-size: 1.5rem;
        width: max-content;

        .nav__link {
          @include common-nav-link-styles;
          transition: all 0.3s ease-in-out;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: lightgray;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      transform: translateY(-100%);
      z-index: 9999;

      &.show {
        transform: translateY(0);
      }

      .nav__item {
        margin: 1em;
        font-weight: 600;
        font-size: 1.5rem;

        .nav__link {
          @include common-nav-link-styles;
          transition: all 0.3s ease-in-out;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .menu-icon {
    display: flex;
    width: 8rem;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}
