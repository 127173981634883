@import "../../styles/main.scss";

.contact__container {
  padding: 10rem 8rem 1rem 8rem !important;

  @media screen and (max-width: $breakpoint-lg) {
    padding: 3rem 2rem 1rem 2rem !important;
  }

  .contact__intro__first {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;

    .contact__intro__text_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      max-width: 400px;

      @media screen and (max-width: $breakpoint-md) {
        max-width: 100%;
      }
      .contact__intro__text__title {
        font-size: $font-size-xxl;
        margin-bottom: 2rem;
        font-family: $font-family-h1;
      }
      .contact__intro__text {
        font-size: $font-size-lg;
        line-height: 1.5;
        margin: 0;
        width: 100%;
        text-align: start;

        a {
          text-decoration: none;
          color: $primary-color;
          transition: all 0.3s ease;
          font-weight: 600;
        }
      }
    }

    .contact__intro__img {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        max-width: 450px;
        height: auto;
        border-radius: 40px;

        @media screen and (max-width: $breakpoint-lg) {
          max-width: 300px;
        }
      }

      .contact__intro__img__text {
        font-size: $font-size-lg;
        margin: 0.5rem 0 0 0;
        text-align: left;
      }
    }

    @media screen and (max-width: $breakpoint-md) {
      flex-direction: column-reverse;
      gap: 2rem;
    }
  }

  .contact__intro__second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 3rem 0;

    .contact__intro__text {
      font-size: $font-size-lg;
      line-height: 1.5;
      margin: 0;
      width: 100%;
      text-align: start;
    }
  }

  .contact__listing__container {
    display: grid;
    grid-template-columns: 1fr 5fr;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 3rem 0;

    @media screen and (max-width: $breakpoint-md) {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    .contact__contacts__title {
      font-size: $font-size-md;
      font-style: italic;
      font-weight: 700;
      margin: 0;
      line-height: 1.5;
      font-family: $font-family-h1;
      text-align: left;
    }

    .contact__contacts__text__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1rem;

      .contact__contacts__text {
        font-size: $font-size-lg;
        line-height: 1.5;
        margin: 0;
        width: 100%;
        text-align: start;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        text-align: start;
        font-size: $font-size-lg;
        line-height: 1.5;

        li a {
          text-decoration: none;
          color: $primary-color;
          transition: all 0.3s ease;
          font-weight: 600;
        }
      }
    }
  }
}
