@import "../../../styles/main.scss";

.workpage__navigation{
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;

  Link, a {
    text-decoration: none;
    color: $primary-color;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1rem 2rem;
    border-radius: 40px;
    transition: all 0.3s ease;

    &:hover {
      background-color: $primary-color;
      color: white;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    align-items: center;

    Link, a {
      margin-bottom: 1rem;
    }
  }
}