// Colors
$primary-color: #1a1a1a;
$text-color: #333;

// Fonts
$font-family-title: 'PlayFair Display', serif;
$font-family-h1: 'Roboto', sans-serif;
$font-family-base: 'EB Garamond', sans-serif;

// Font sizes
$font-size-xs: 0.75rem;
$font-size-sm: 1rem;
$font-size-md: 1.25rem;
$font-size-lg: 1.5rem;
$font-size-xl: 2rem;
$font-size-xxl: 2.5rem;

// Breakpoints
$breakpoint-xs: 320px;
$breakpoint-sm: 480px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;