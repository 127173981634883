@import "../../styles/main.scss";

.not-implemented-page__wrapper {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-align: center;
  padding: 3rem;

  @media (max-width: $breakpoint-md) {
    margin-top: 8rem;
    grid-template-columns: 1fr;
    height: auto;
  }

  .not-implemented-page__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 3rem;
    text-align: left;

    @media (max-width: $breakpoint-md) {
      padding: 0;
      align-items: center;
      text-align: center;

      .not-implemented-page__h1 {
        margin-bottom: 0;
      }

      .not-implemented-page__h2 {
        margin-top: 0;
        word-wrap: break-word;
      }

      .not-implemented-page__btn {
        width: 100%;
        word-wrap: break-word;
      }
    }

    .not-implemented-page__btn {
      padding: 1rem 2rem;
      border: 1px solid $primary-color;
      border-radius: 40px;
      background-color: transparent;
      color: $primary-color;
      font-family: $font-family-base;
      font-size: 1.2rem;
      font-weight: 600;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.3s ease;

      &:hover {
        background-color: $primary-color;
        color: white;
      }
    }
  }
}
