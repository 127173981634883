@import "../../../styles/main.scss";

.work__page__h1 {
  font-size: 2.5rem;
  font-family: $font-family-h1;
  line-height: 1.2;
  margin-bottom: 2rem;
  width: 75%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.work__page__h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-family: $font-family-title;
}

.work__page__h4 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  font-family: $font-family-title;
}

