@import "../../styles/main.scss";

.about__container {
  @media screen and (max-width: $breakpoint-sm) {
    padding: 1rem;
  }

  .about__intro {
    font-family: $font-family-title;
    font-size: 1.5rem;
    line-height: 1.5;
  }

  .about__story__item {
    margin-bottom: 2rem;

    @media screen and (max-width: $breakpoint-md) {
      width: 100%;
    }

    .about__story__item__text {
      margin-bottom: 2rem;
      .about__stroy__item__title {
        font-size: 2rem;
        font-family: $font-family-h1;
        line-height: 1.5;
        font-weight: 700;
        margin-bottom: 1rem;
      }

      .about__story__item__content {
        font-size: 1.4rem;
        line-height: 1.5;
      }

      .about__story__item__link {
        height: auto;
        display: flex;

        a {
          padding: 1rem 2rem;
          border: 1px solid $primary-color;
          border-radius: 40px;
          background-color: transparent;
          color: $primary-color;
          font-family: $font-family-base;
          font-size: 1.2rem;
          font-weight: 600;
          text-decoration: none;
          text-transform: uppercase;
          transition: all 0.3s ease;

          &:hover {
            background-color: $primary-color;
            color: white;
          }

          @media screen and (max-width: $breakpoint-sm) {
            font-size: 1rem;
            text-align: center;
            margin: 0 auto;
          }
        }
      }
    }

    .about__story__item__img {
      margin-top: 2rem;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}
