$font-family-title: "PlayFair Display", serif;
$font-family-base: "EB Garamond", sans-serif;

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: lightgray;
}

body {
  margin: 0;
  padding: 0;
  padding-top: 20vh;
  line-height: 1;
  font-weight: 400;
  color: #333;
  font-family: $font-family-base;
  overflow-x: hidden;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "EB Garamond", sans-serif;
}
