@import "../../styles/main.scss";

.footer {
  background-color: #1a1a1a;
  color: #fff;
  padding: 2rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.5;
  margin-top: 2rem;
  font-family: $font-family-title;

  .footer__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 5rem;

    @media screen and (max-width: $breakpoint-md) {
      padding: 0 1rem;
    }
    @media screen and (max-width: $breakpoint-sm) {
      padding: 0;
    }

    .footer__title {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 1rem;

      @media screen and (max-width: $breakpoint-md) {
        font-size: 1.5rem;
      }
    }

    .footer__contacts {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      font-family: $font-family-base;

      @media screen and (max-width: $breakpoint-md) {
        font-size: 1.2rem;
      }
    }
  }
}
