@import '_variables.scss';
@import '_globals.scss';
@import '_mixins.scss';

// TRANSITIONS - FULL PAGE
.slide-in{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0f0f0f;
  transform-origin: bottom;
}

.slide-out{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0f0f0f;
  transform-origin: top;
}